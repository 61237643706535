<template>
<div>
  <h3>{{userOrg.org_name}}</h3>
</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileSuggestedPages from './ProfileSuggestedPages.vue'
import ProfileTwitterFeed from './ProfileTwitterFeed.vue'
import ProfilePost from './ProfilePost.vue'
import ProfileLatestPhotos from './ProfileLatestPhotos.vue'
import ProfileSuggestion from './ProfileSuggestion.vue'
import ProfilePolls from './ProfilePolls.vue'
import profileBottom from './profileBottom.vue'

import { get, sync, call } from 'vuex-pathify'

/* eslint-disable global-require */
export default {

  components: {
    BRow,
    BCol,

    ProfileHeader,
    ProfileAbout,
    ProfileSuggestedPages,
    ProfileTwitterFeed,
    ProfilePost,
    ProfileLatestPhotos,
    ProfileSuggestion,
    ProfilePolls,
    profileBottom,
  },

  data() {
    return {
      profileData: {},
      userOrgData: {},
    }
  },

  computed: {
    orgName() {
      // return this.$store.userOrgData.org_name ? this.$store.userOrgData.org_name : "";
      return "";
    },

    user: sync('user/profile'), 
    userOrg: sync('user/org')
  }, 

  mounted() {
    console.log("org profile store.user:", this.user)
    console.log("org profile store.userOrgData:", this.$store.userOrgData)
    console.log("org data:", this.userOrgData)
    // console.log("org data user:", user)



  },

  created() {
    this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
